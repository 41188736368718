import React from "react"
import { Link as LinkGatsby, navigate } from "gatsby"
import styled from "@emotion/styled"
import tw from "twin.macro"
import isEmpty from "ramda/src/isEmpty"
import path from "ramda/src/path"
import take from "ramda/src/take"
import length from "ramda/src/length"
import gt from "ramda/src/gt"
import compose from "ramda/src/compose"
import __ from "ramda/src/__"
import equals from "ramda/src/equals"
import concat from "ramda/src/concat"
import head from "ramda/src/head"
import type from "ramda/src/type"
import FocusLock from "react-focus-lock"

import WrapperHeader from "./WrapperHeader"
import LogoLocalDeProva from "./LogoLocalDeProva"
import Nav from "./Nav"
import ButtonPrimary from "../ButtonPrimary"

import useSelectCity from "../../hooks/useSelectCity"

import {
  getCityName,
  getStateName,
  getFormattedPlacesName,
} from "../../utils/places"

const takeThree = take(3)
const gtThanZero = gt(__, 0)
const getSlug = path(["fields", "slug"])
const equalsOne = equals(1)
const isFunction = compose(equals("Function"), type)

function truncate(text, maxLength = 40) {
  if (length(text) <= maxLength) {
    return text
  } else {
    return concat(take(maxLength, text), "...")
  }
}

const SearchWrapper = styled.div`
  ${tw`absolute lg:static bottom-0 lg:bottom-auto w-full lg:w-3/5 bg-white`}

  max-height: 45px;
`

const LabelSrOnly = styled.label`
  ${tw`sr-only`}
`

const SearchInput = styled.input`
  ${tw`transition-colors duration-300 ease-in-out bg-secondary bg-opacity-20 text-primary py-3 text-sm px-4 rounded-md w-full focus:bg-white  placeholder-primary font-sans outline-none focus:shadow-secondary-opacity-60 shadow-secondary-opacity-20`}
`

const SearchResult = styled.div`
  ${tw`shadow-lg bg-white border-secondary border rounded-md mt-3 px-3 py-1`}
`

const NoResult = styled.div`
  ${tw`w-full py-3 text-lg border-b border-paragraph border-opacity-30`}
`

const RowResult = styled(LinkGatsby)`
  ${tw`
    w-full py-3 text-lg border-b border-paragraph border-opacity-30 hover:bg-blue
    hover:bg-opacity-40 block outline-none
  `}

  &:not(:focus) {
    ${({ hovered }) => hovered && tw`bg-blue bg-opacity-40`}
  }

  &:focus {
    ${tw`bg-blue bg-opacity-40`}
  }
`

const Estado = styled.span`
  ${tw`uppercase`}
`

const MoreResults = styled.div`
  ${tw`w-full text-center py-6 text-sm`}
`

const InformationResult = styled.div`
  ${tw`mt-3 mb-2 text-paragraph text-sm`}
`

function ResultCity(props) {
  const { children: city, hovered } = props

  return (
    <RowResult hovered={hovered ? 1 : undefined} to={getSlug(city)}>
      {getCityName(city)} - <Estado>{getStateName(city)}</Estado>
    </RowResult>
  )
}

function handleKeyPress(event) {
  if ("/" === event.key) {
    document.getElementById("cidade").focus()
    event.preventDefault()
  }
}

export default function Header({ setBackpackMood }) {
  React.useEffect(() => {
    document.addEventListener("keypress", handleKeyPress, false)

    return () => {
      document.removeEventListener("keypress", handleKeyPress, false)
    }
  }, [])

  const [term, setTerm] = React.useState("")
  const { cities, cursor, setCursor, selectNextCity } = useSelectCity(term)

  const hasOneCity = React.useMemo(() => {
    return equalsOne(length(cities))
  }, [cities])

  const cityInformation = React.useMemo(() => {
    if (hasOneCity) {
      const locais = getFormattedPlacesName(cities)

      return locais && truncate(`Locais: ${locais}`)
    } else {
      return undefined
    }
  }, [cities, hasOneCity])

  const moreResults = React.useMemo(() => {
    return length(cities) - 3
  }, [cities])

  const setBackpackMoodIfIsFunction = React.useCallback(
    mood => {
      if (isFunction(setBackpackMood)) {
        setBackpackMood(mood)
      }
    },
    [setBackpackMood]
  )

  const resetBackpackMood = React.useCallback(() => {
    setBackpackMoodIfIsFunction(prevMood =>
      "shocked" === prevMood || "sad" === prevMood ? "excited" : prevMood
    )
  }, [setBackpackMoodIfIsFunction])

  React.useEffect(() => {
    let mounted = true

    if (hasOneCity && !cityInformation) {
      if (mounted) {
        setBackpackMoodIfIsFunction("shocked")
      }
    }

    return () => {
      mounted = false
    }
  }, [cityInformation, hasOneCity, setBackpackMoodIfIsFunction])

  React.useEffect(() => {
    if (!hasOneCity) {
      resetBackpackMood()
    }
  }, [hasOneCity, resetBackpackMood])

  React.useEffect(() => {
    let mounted = true

    if (!term) {
      if (mounted) {
        resetBackpackMood()
        setCursor(0)
      }
    }

    return () => {
      mounted = false
    }
  }, [term, resetBackpackMood, setCursor])

  React.useEffect(() => {
    let mounted = true

    if (cityInformation) {
      if (mounted) {
        resetBackpackMood()
      }
    }

    return () => {
      mounted = false
    }
  }, [cityInformation, resetBackpackMood])

  React.useEffect(() => {
    let mounted = true

    if (term && isEmpty(cities)) {
      if (mounted) {
        setBackpackMoodIfIsFunction("sad")
      }
    }

    return () => {
      mounted = false
    }
  }, [term, cities, setBackpackMoodIfIsFunction])

  return (
    <WrapperHeader>
      <LogoLocalDeProva />
      <SearchWrapper>
        <LabelSrOnly htmlFor="cidade">Cidade</LabelSrOnly>
        <FocusLock disabled={isEmpty(term) || !hasOneCity}>
          <FocusLock
            disabled={isEmpty(term) || (hasOneCity && !cityInformation)}
          >
            <SearchInput
              id="cidade"
              value={term}
              onChange={event => setTerm(event.target.value)}
              placeholder='Procure por uma cidade (pressione "/")'
              autoComplete="off"
              onKeyDown={event => {
                if ("Escape" === event.key) {
                  setTerm("")
                } else if ("Enter" === event.key) {
                  if (!isEmpty(cities)) {
                    const citySlug = getSlug(cities[cursor].node)
                    navigate(citySlug)
                  }
                } else if ("ArrowUp" === event.key) {
                  event.preventDefault()
                } else if ("ArrowDown" === event.key) {
                  event.preventDefault()
                } else if ("Tab" === event.key) {
                  selectNextCity()
                }
              }}
            />
          </FocusLock>

          {term && (
            <SearchResult>
              {isEmpty(cities) ? (
                <>
                  <NoResult>Nenhuma cidade encontrada.</NoResult>
                  <ButtonPrimary onClick={() => navigate("cadastrar-local")}>
                    Adicionar cidade
                  </ButtonPrimary>
                </>
              ) : (
                <>
                  {takeThree(cities).map(({ node: city }, index) => (
                    <ResultCity
                      key={`${getCityName(city)}-${getStateName(city)}`}
                      hovered={!hasOneCity && equals(index, cursor)}
                    >
                      {city}
                    </ResultCity>
                  ))}

                  {hasOneCity && (
                    <>
                      {cityInformation ? (
                        <InformationResult>{cityInformation}</InformationResult>
                      ) : (
                        <>
                          <InformationResult>
                            Ops... ainda não temos nenhuma informação sobre{" "}
                            {getCityName(head(cities).node)}.
                          </InformationResult>

                          <InformationResult>
                            Se você conhece um local, considere ajudar outros
                            concurseiros adicionando informações.
                          </InformationResult>

                          <ButtonPrimary
                            onClick={() =>
                              navigate("cadastrar-local", {
                                state: {
                                  city: `${getCityName(
                                    head(cities).node
                                  )} - ${getStateName(head(cities).node)}`,
                                },
                              })
                            }
                          >
                            Adicionar um local
                          </ButtonPrimary>
                        </>
                      )}
                    </>
                  )}

                  {gtThanZero(moreResults) && (
                    <MoreResults>
                      ...e mais {moreResults}{" "}
                      {equalsOne(moreResults) && "resultado"}{" "}
                      {moreResults > 1 && "resultados"}
                    </MoreResults>
                  )}
                </>
              )}
            </SearchResult>
          )}
        </FocusLock>
      </SearchWrapper>
      <Nav />
    </WrapperHeader>
  )
}
